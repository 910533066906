import imagePath from "/util/nextjs/imagePath";

// Our flag icons (https://github.com/GlobalArtInc/round-flags) are named using country codes, not locales.
// We need a custom mapping to handle locales that don't directly correlate to a specific country.
const mapLocaleToCountryCode: Partial<Record<string, string>> = {
  "ca-es": "es-ca",
};

export function getRoundFlagPath(lang: string) {
  const langLower = lang.toLowerCase();
  const countryCode = mapLocaleToCountryCode[langLower] ?? langLower.split("-")[1];
  return imagePath(`/flags/${countryCode}.svg`);
}
