import dynamic from "next/dynamic";
import Head from "next/head";
import { useRouter } from "next/router";
import useTranslation from "next-translate/useTranslation";

import { MetaAdapter, Return as RouteReturn } from "/routes/generateRoute/generateRoute";
import { openSearchPath, rssPath } from "/routes/pages";
import useLocaleSwitcher from "/shared/hooks/state/useLocaleSwitcher";
import usePagePopState from "/shared/hooks/state/usePagePopState/usePagePopState";
import usePageViewTracker from "/shared/hooks/util/usePageViewTracker";
import GoogleOneTap from "/shared/interactions/GoogleOneTap";
import SwitchLocaleBanner from "/shared/interactions/SwitchLocaleModal/SwitchLocaleBanner";
import useLanguageDisharmonyDetector from "/shared/interactions/SwitchLocaleModal/useLanguageDisharmonyDetector";
import { Locale } from "/types/locale";
import isBrowser from "/util/isBrowser";
import getLangFromLocale from "/util/localization/getLangFromLocale";
import { TranslatedSlugs } from "/util/localization/getTranslatedSlugsByRoute";
import { availableLocales } from "/util/localization/locales";
import imagePath from "/util/nextjs/imagePath";
import { defaults } from "/util/uri";

const SwitchLocaleModal = dynamic(() => import("/shared/interactions/SwitchLocaleModal/SwitchLocaleModal"));

export interface Props {
  // For when the page isn't translated so Google doesn't think other pages are available.
  onlyDefaultLocale?: boolean;

  // Overrides the default title
  title?: string;
  // Overrides the description
  description?: string;
  // Adds an og:title
  ogTitle?: string;
  // Adds an og:description
  ogDescription?: string;
  // Overrides the og:image
  ogImage?: string;
  // Adds an og:type
  ogType?: string;
  // Adds an og:site_name
  ogSiteName?: string;
  // Adds a `<meta name="robots" /> tag with the content of the string provided
  robots?: string;
  // Adds a `<meta name="keywords" /> tag with the content of the string provided
  keywords?: string;
  // Adds a `<meta property="article:modified_time" /> tag with the content of the string provided
  articleModifiedTime?: string;
  // Adds a `<meta property="article:tag" /> tag with the content of the string provided
  articleTag?: string;
  // If provided this is the URL that will be tracked instead of window.location.href
  trackingUrl?: string | null;
  // if provided with falsy value, google one tap won't be triggered, by default is true
  showGoogleOneTap?: boolean;

  // This supplies you with the props needed for a route's `metaAdapter` function.
  // It is a render prop to support passing in dynamic params.
  //
  // Example:
  // metaRoute=(props => route.metaAdapter({...props, anyDynamicParamsNeeded: 'here' }))
  metaRoute: (props: MetaAdapter<unknown>) => RouteReturn;

  // If this prop is present it will be used to generate the canonical tag.
  // The reason for this is that the canonical tag can deviate from the current url.
  // The prop `canonicalLocaleOverride` works both when `canonicalMetaRoute` is present and not.
  //
  // For example there are times when we need to override the query for only the canonical tag.
  canonicalMetaRoute?: (props: MetaAdapter<unknown>) => RouteReturn;

  // Will force the canonical locale to the provided locale
  canonicalLocaleOverride?: string;

  translatedSlugs?: TranslatedSlugs;

  // If true then adds a `<meta name="robots" content="noindex" />`
  noIndex?: boolean;

  // If provided will be set as the x-default value: <link rel="alternate" hrefLang="x-default" href={xDefault} />
  xDefault?: (props: MetaAdapter<unknown>) => RouteReturn;

  // used to render only modal's head when there are two heads
  isMediumHead?: boolean;
}

function useCanonical({ metaRoute, canonicalMetaRoute, translatedSlugs = {}, canonicalLocaleOverride }: Props) {
  const i18n = useTranslation();
  const { query } = useRouter();
  const { href } = (canonicalMetaRoute || metaRoute)({
    i18n,
    includeDomain: true,
    locale: (canonicalLocaleOverride || i18n.lang) as Locale,
    translatedSlugs,
  });
  const [pathname, search] = href.split("?");
  const searchWithPage = defaults(search, {
    page: query.page,
  });
  const hrefWithPage = searchWithPage ? `${pathname}?${searchWithPage}` : pathname;

  return hrefWithPage;
}

function getTrackingUrl(url: Props["trackingUrl"]) {
  if (url !== undefined) {
    return url;
  }
  if (isBrowser()) {
    return window.location.href;
  }
  return null;
}

export default function DefaultHead(props: Props) {
  const {
    onlyDefaultLocale,
    title,
    description,
    ogImage,
    ogTitle,
    ogDescription,
    ogSiteName,
    ogType,
    metaRoute,
    translatedSlugs = {},
    robots,
    keywords,
    articleModifiedTime,
    articleTag,
    trackingUrl,
    noIndex,
    xDefault,
    isMediumHead,
    showGoogleOneTap = true,
  } = props;
  const i18n = useTranslation();
  const { t, lang: currentLocale } = i18n;
  const canonical = useCanonical(props);
  const { state } = usePagePopState();
  const pageViewTrackerUrl = getTrackingUrl(trackingUrl);

  useLanguageDisharmonyDetector();
  usePageViewTracker(pageViewTrackerUrl);

  const locales = availableLocales.map((locale) => {
    const { href } = metaRoute({
      i18n,
      locale: locale as Locale,
      translatedSlugs,
    });

    return { href, locale: locale as Locale };
  });

  const [
    {
      switchLocale: { open: showSwitchLocaleModal },
    },
  ] = useLocaleSwitcher();

  if (state && !isMediumHead) {
    // modal is displayed, but this head component doesn't belong to modal
    return null;
  }

  const getRobots = () => {
    const maxImgPreview = "max-image-preview:large";

    if (!(noIndex || robots)) {
      return maxImgPreview;
    }

    if (noIndex) {
      return `noindex, ${maxImgPreview}`;
    }

    if (robots) {
      return `${robots}, ${maxImgPreview}`;
    }
  };

  return (
    <>
      {showSwitchLocaleModal && <SwitchLocaleModal locales={locales} />}
      <SwitchLocaleBanner locales={locales} />
      {showGoogleOneTap && <GoogleOneTap />}

      <Head>
        <meta name="viewport" content="width=device-width, initial-scale=1, maximum-scale=1" />
        <meta name="theme-color" content="#232a34" />
        <meta name="msapplication-TileColor" content="#54ca84" />
        <title>{title || t("common:meta.title")}</title>
        <meta name="description" content={description || t("common:meta.description")} />
        <meta name="robots" content={getRobots()} />
        {keywords && <meta name="keywords" content={keywords} />}
        {articleModifiedTime && <meta property="article:modified_time" content={articleModifiedTime} />}
        {articleTag && <meta property="article:tag" content={articleTag} />}
        <meta property="og:image" content={ogImage || imagePath("/meta/pexels-stock-photos.jpg")} />
        {ogTitle && <meta property="og:title" content={ogTitle} />}
        {ogDescription && <meta property="og:description" content={ogDescription} />}
        {ogSiteName && <meta property="og:site_name" content={ogSiteName} />}
        {ogType && <meta property="og:type" content={ogType} />}
        <meta property="og:url" content={canonical} />
        <link href={canonical} rel="canonical" />
        <link
          rel="alternate"
          hrefLang="x-default"
          href={
            (xDefault || metaRoute)({
              i18n,
              includeDomain: true,
              locale: "en-US",
              translatedSlugs,
            }).href
          }
        />

        {!onlyDefaultLocale &&
          availableLocales.map((locale) => {
            let { href } = metaRoute({
              i18n,
              includeDomain: true,
              locale: locale as Locale,
              translatedSlugs,
            });

            // Set the hrefLang of the current locale to the canonical if the canonical is different
            // This is to ensure hreflang of the current locale always aligns with the canonical
            if (locale === currentLocale) {
              href = canonical;
            }

            return <link key={locale} rel="alternate" hrefLang={getLangFromLocale(locale)} href={href} />;
          })}

        <link
          href={rssPath({ i18n, includeDomain: true }).href}
          rel="alternate"
          title="RSS"
          type="application/rss+xml"
        />
        <link
          rel="search"
          type="application/opensearchdescription+xml"
          title="Pexels"
          href={openSearchPath({ i18n }).href}
        />
        <link href={imagePath("/meta/favicon.ico")} rel="shortcut icon" type="image/x-icon" />
        <link rel="icon" sizes="192x192" href={imagePath("/meta/pexels-icon.png")} />
        <link rel="apple-touch-icon" href={imagePath("/meta/apple-touch-icon.png")} />
        <link rel="mask-icon" href={imagePath("/meta/safari-pinned-tab.svg")} color="#54ca84" />
        <meta name="application-name" content="Pexels" />
        <script
          type="application/ld+json"
          dangerouslySetInnerHTML={{
            __html: JSON.stringify({
              "@context": "http://schema.org",
              "@type": "WebSite",
              name: "Pexels",
              url: "https://www.pexels.com/",
              potentialAction: {
                "@type": "SearchAction",
                target: "https://www.pexels.com/search/{search_term_string}/",
                "query-input": "required name=search_term_string",
              },
            }),
          }}
        />
        <script
          type="application/ld+json"
          dangerouslySetInnerHTML={{
            __html: JSON.stringify({
              "@context": "http://schema.org",
              "@type": "Organization",
              name: "Pexels",
              url: "https://www.pexels.com",
              logo: imagePath("/meta/pexels-logo.png"),
              sameAs: [
                "https://www.facebook.com/pexels",
                "https://twitter.com/pexels",
                "https://www.pinterest.com/pexels/",
                "https://instagram.com/pexels/",
              ],
            }),
          }}
        />
      </Head>
    </>
  );
}
