import { RefObject, useRef } from "react";

import isBrowser from "/util/isBrowser";

const duration = 400; // ms

function easeInOutQuad(t: number, b: number, c: number, d: number): number {
  /* eslint-disable no-param-reassign */
  t /= d / 2;

  if (t < 1) {
    return (c / 2) * t * t + b;
  }
  t -= 1;

  return (-c / 2) * (t * (t - 2) - 1) + b;
  /* eslint-enable no-param-reassign */
}

export default function useScrollAnimation(ref: RefObject<HTMLDivElement>) {
  const scrollElement = ref.current;
  const startPos = useRef<number>(0);

  const triggerScroll = (direction: "left" | "right" | "up" | "down") => {
    if (!isBrowser()) {
      return;
    }

    if (!scrollElement) {
      return;
    }

    const amountToScroll = window.innerWidth / 2; // px
    const verticalAmountToScroll = scrollElement.clientHeight / 2; // px
    let change = 0;

    if (direction == "left" || direction == "right") {
      startPos.current = scrollElement.scrollLeft;
      change = direction === "left" ? -amountToScroll : amountToScroll;
    } else {
      startPos.current = scrollElement.scrollTop;
      change = direction === "up" ? -verticalAmountToScroll : verticalAmountToScroll;
    }

    let start: DOMHighResTimeStamp;

    const animateScroll = (timestamp: DOMHighResTimeStamp) => {
      if (start === undefined) {
        start = timestamp;
      }

      const currentTime = timestamp - start;
      const val = easeInOutQuad(currentTime, startPos.current, change, duration);

      if (direction == "left" || direction == "right") {
        scrollElement.scrollLeft = val;
      } else {
        scrollElement.scrollTop = val;
      }

      if (currentTime < duration) {
        window.requestAnimationFrame(animateScroll);
      }
    };

    window.requestAnimationFrame(animateScroll);
  };

  return {
    triggerScroll,
  };
}
