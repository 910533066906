import cn from "classnames";
import { Children, cloneElement, isValidElement, ReactElement, ReactNode } from "react";

import useMemoStyle from "/shared/hooks/dom/useMemoStyle";
import ChevronLeft from "/shared/icons/custom/chevron_left.svg";
import ChevronRight from "/shared/icons/custom/chevron_right.svg";
import spacing, { SpacingProps } from "/shared/ui/Spacing/spacing";
import { Colors } from "/types/colors";
import { Spaces } from "/types/spaces";

import Icon from "../Icon/Icon";
import ChildComponentWithSpacingProps from "./ChildComponentWithSpacingProps";
import css from "./SpacingGroup.module.scss";
import useOverflowScrollElements from "./useOverflowScrollElements";
import useScrollAnimation from "./useScrollAnimation";

export interface Props extends SpacingProps {
  className?: string;
  spaceBetween: Spaces;
  children?: ReactNode | ReactElement<SpacingProps> | ReactElement<SpacingProps>[];
  center?: "vertical" | "horizontal" | "both";
  responsive?: "wrap" | "overflow";
  justifyContent?: "flex-start" | "flex-end" | "center";
  showOverflowGradients?: boolean;
  showOverflowArrows?: boolean;
  assumeContentWillOverflow?: boolean;
  overflowArrowColor?: Colors;
  // Set orientation of overflow
  vertical?: boolean;
}

// This is used to set the element that keeps track of the responsive scroll containers position.
// By setting it to a min of 1px we ensure that it will be picked up by the IntersectionObserver
// when it scrolls in to view.
const sentinelStyle = { minWidth: 1 };

/**
 * Layout component for linearly aligning items, with spacing between elements. By default, scroll arrows will be shown
 * if the content overflows the container.
 *
 * Use <SpacingGroup /> for sections we want to overflow within the main <Wrapper /> or a container. For sections, we
 * want to overflow to the end of the screen, use <OverflowGroup />.
 */
export default function SpacingGroup(props: Props) {
  const {
    center,
    children,
    spaceBetween,
    responsive,
    showOverflowGradients = true,
    showOverflowArrows = true,
    overflowArrowColor = "grey$7F7F7F",
    assumeContentWillOverflow,
    className,
    justifyContent,
    vertical,
  } = props;
  const childComponents = Children.toArray(children).filter((c) => isValidElement(c)) as ReactElement<SpacingProps>[];
  const showOverflowElements = responsive === "overflow";
  const scroll = useOverflowScrollElements(showOverflowElements, assumeContentWillOverflow);
  const { triggerScroll } = useScrollAnimation(scroll.ref);
  const negativeMargins = useMemoStyle({
    marginBottom: -spaceBetween,
  });

  if (childComponents.length <= 0) {
    return null;
  }

  return (
    <div
      className={cn(spacing(props), css.wrapper, className, {
        [css.horizontalCenter]: center === "horizontal" || center === "both",
      })}
    >
      <div
        ref={scroll.ref}
        style={responsive === "wrap" ? negativeMargins : undefined}
        className={cn(css.default, {
          [css.wrap]: responsive === "wrap",
          [css.overflow]: responsive === "overflow",
          [css.verticalCenter]: center === "vertical" || center === "both",
          [css.vertical]: vertical,
          [css.justifyContentEnd]: justifyContent === "flex-end",
          [css.justifyContentStart]: justifyContent === "flex-start",
          [css.justifyContentCenter]: justifyContent === "center",
        })}
      >
        <div ref={scroll.leftRef} style={sentinelStyle} />

        {showOverflowElements && (
          <div
            className={cn({
              [css.leftOverlay]: !vertical,
              [css.topOverlay]: vertical,
              [css.open]: scroll.overflowElementsToShow.left,
              [css.hasGradient]: showOverflowGradients,
            })}
          >
            {showOverflowArrows && (
              <Icon
                symbol={ChevronLeft}
                fill={overflowArrowColor}
                className={css.overflowArrow}
                onClick={() => (!vertical ? triggerScroll("left") : triggerScroll("up"))}
              />
            )}
          </div>
        )}

        {childComponents.map((child, i) => {
          return (
            <ChildComponentWithSpacingProps
              key={`${child?.key}-${i}`}
              index={i}
              childrenCount={childComponents.length}
              spaceBetween={spaceBetween}
              addBottomMargin={responsive === "wrap"}
            >
              {({ spacingProps }) => cloneElement(child, spacingProps)}
            </ChildComponentWithSpacingProps>
          );
        })}

        {showOverflowElements && (
          <div
            className={cn({
              [css.rightOverlay]: !vertical,
              [css.bottomOverlay]: vertical,
              [css.open]: scroll.overflowElementsToShow.right,
              [css.hasGradient]: showOverflowGradients,
            })}
          >
            {showOverflowArrows && (
              <Icon
                symbol={ChevronRight}
                fill={overflowArrowColor}
                className={css.overflowArrow}
                onClick={() => (!vertical ? triggerScroll("right") : triggerScroll("down"))}
                width={20}
                height={20}
              />
            )}
          </div>
        )}

        <div ref={scroll.rightRef} style={sentinelStyle} />
      </div>
    </div>
  );
}
