import useTranslation from "next-translate/useTranslation";
import { useEffect } from "react";

import useLocaleSwitcher from "/shared/hooks/state/useLocaleSwitcher";
import Close from "/shared/icons/custom/close.svg";
import Button, { ButtonLink } from "/shared/ui/Button/Button";
import Icon from "/shared/ui/Icon/Icon";
import modalCss from "/shared/ui/Modal/Modal.module.scss";
import SpacingGroup from "/shared/ui/SpacingGroup/SpacingGroup";
import Text from "/shared/ui/Text/Text";
import { getRoundFlagPath } from "/shared/util/GetRoundFlagPath";
import { Locale } from "/types/locale";
import isBrowser from "/util/isBrowser";
import getLocaleFromLang from "/util/localization/getLocaleFromLang";
import translatedLanguageName from "/util/localization/translatedLanguageName";

import css from "./SwitchLocaleBanner.module.scss";
import useChangeLanguage from "./useChangeLanguage";

interface Props {
  locales: {
    locale: Locale;
    href: string;
  }[];
}

export default function SwitchLocaleBanner({ locales }: Props) {
  const [{ switchLocaleBanner }, switchLocale] = useLocaleSwitcher();
  const changeLanguage = useChangeLanguage();
  const { t } = useTranslation("common");

  // when this banner is open it overlaps with modals that are positioned at the bottom
  // this appends a class to the body element so that is avoided when the banner is open
  useEffect(() => {
    if (isBrowser()) {
      document.body.classList.add(modalCss.contentAboveSwitchLocaleModal);
    }
  }, [switchLocaleBanner]);

  if (!switchLocaleBanner) {
    return null;
  }

  const { pageLanguage, userLanguage } = switchLocaleBanner;
  const pageLocale = getLocaleFromLang(pageLanguage);
  const userLocale = getLocaleFromLang(userLanguage);
  const pageLocaleData = locales.find(({ locale }) => locale === pageLocale);
  const userLocaleData = locales.find(({ locale }) => locale === userLocale);

  if (!pageLocaleData || !userLocaleData) {
    return null;
  }

  return (
    <div className={css.container}>
      <Text size="h$18" className={css.title}>
        {t("locale.choose_your_language")}:
      </Text>

      <SpacingGroup spaceBetween={8} className={css.buttons}>
        <ButtonLink
          href={pageLocaleData.href}
          onClick={changeLanguage(pageLocaleData.locale)}
          icon={
            <img
              src={getRoundFlagPath(pageLocaleData.locale)}
              className={css.flag}
              alt={translatedLanguageName[pageLocaleData.locale]}
            />
          }
        >
          {translatedLanguageName[pageLocaleData.locale]}
        </ButtonLink>

        <ButtonLink
          href={userLocaleData.href}
          onClick={changeLanguage(userLocaleData.locale)}
          icon={
            <img
              src={getRoundFlagPath(userLocaleData.locale)}
              className={css.flag}
              alt={translatedLanguageName[userLocaleData.locale]}
            />
          }
        >
          {translatedLanguageName[userLocaleData.locale]}
        </ButtonLink>

        <Button
          onClick={() => switchLocale({ type: "switch-locale-banner-close" })}
          icon={<Icon symbol={Close} />}
        ></Button>
      </SpacingGroup>
    </div>
  );
}
