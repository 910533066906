import { useCallback } from "react";

import useLocaleSwitcher from "/shared/hooks/state/useLocaleSwitcher";
import { Locale } from "/types/locale";
import { setCookie } from "/util/cookies/cookies";

import { setOverrideLocaleLang } from "./useLanguageDisharmonyDetector";

export default function useChangeLanguage() {
  const [, switchLocale] = useLocaleSwitcher();

  return useCallback(
    (locale: Locale) => {
      return () => {
        setCookie("locale", locale); // set for the pexels rails app
        switchLocale({ type: "switch-locale-modal-close" });
        switchLocale({ type: "switch-locale-banner-close" });
        setOverrideLocaleLang(locale);
      };
    },
    [switchLocale],
  );
}
