import useTranslation from "next-translate/useTranslation";
import { useEffectOnce } from "react-use";

import useLocaleSwitcher from "/shared/hooks/state/useLocaleSwitcher";
import { Locale } from "/types/locale";
import isBrowser from "/util/isBrowser";
import getLangFromLocale, { getAllAvailableLangs } from "/util/localization/getLangFromLocale";
import { defaultLocale } from "/util/localization/locales";

const hideSwitchLocaleKey = "hide_switch_locale";

interface OverrideStorage {
  hideUntil: Date;
  language: string;
}

export function setOverrideLocaleLang(locale: Locale) {
  const daysHidden = 60;
  const hideUntil = new Date();

  hideUntil.setDate(hideUntil.getDate() + daysHidden);

  const storage: OverrideStorage = {
    hideUntil,
    language: getLangFromLocale(locale),
  };

  localStorage.setItem(hideSwitchLocaleKey, JSON.stringify(storage));
}

function getOverrideLocaleLang() {
  const hideSwitchLocale = localStorage.getItem(hideSwitchLocaleKey);

  if (!hideSwitchLocale) {
    return null;
  }

  try {
    const { hideUntil, language } = JSON.parse(hideSwitchLocale) as OverrideStorage;

    if (hideUntil > new Date()) {
      return null;
    }

    if (!language) {
      return null;
    }

    return getLangFromLocale(language);
  } catch (e) {
    Sentry.captureException(e);

    return null;
  }
}

function hasLanguageDisharmony({ userLanguage, pageLanguage }: { userLanguage: string; pageLanguage: string }) {
  const forceOverrideLocale = getOverrideLocaleLang();

  if (forceOverrideLocale && forceOverrideLocale === pageLanguage) {
    return false;
  }

  if (pageLanguage === userLanguage) {
    return false;
  }

  const availableLangs = getAllAvailableLangs();

  return availableLangs.has(userLanguage);
}

export default function useLanguageDisharmonyDetector() {
  const { lang } = useTranslation();
  const [, switchLocale] = useLocaleSwitcher();

  useEffectOnce(() => {
    if (!isBrowser()) {
      return;
    }

    const userLanguage = getLangFromLocale(navigator.language || navigator.userLanguage || defaultLocale);
    const pageLanguage = getLangFromLocale(lang);
    const languageDisharmony = hasLanguageDisharmony({
      userLanguage,
      pageLanguage,
    });

    if (!languageDisharmony) {
      return;
    }

    switchLocale({
      type: "switch-locale-banner-open",
      payload: { pageLanguage, userLanguage },
    });
  });
}
