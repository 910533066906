import { useEffect, useState } from "react";

// Module-scoped variable to ensure the polyfill is only loaded once
let polyfillLoadedOrAttempted = false;

/**
 * Custom hook to ensure IntersectionObserver is available or loaded as a polyfill.
 * @returns {boolean} intersectionObserverReady - Indicates if IntersectionObserver is ready to use.
 */
export const useIntersectionObserverReady = (): boolean => {
  const [intersectionObserverReady, setIntersectionObserverReady] = useState<boolean>(
    () => typeof window !== "undefined" && typeof window.IntersectionObserver !== "undefined",
  );

  useEffect(() => {
    if (polyfillLoadedOrAttempted || intersectionObserverReady) {
      return;
    }

    polyfillLoadedOrAttempted = true;

    const loadIntersectionObserverPolyfill = async () => {
      if (typeof window === "undefined") {
        // Server-side rendering, no need to load polyfill
        polyfillLoadedOrAttempted = true;
        return;
      }

      if (typeof window.IntersectionObserver !== "undefined") {
        // Browser supports IntersectionObserver, no need to load polyfill
        setIntersectionObserverReady(true);
      } else {
        // Attempt to load the polyfill
        try {
          await import("intersection-observer");
        } catch (e) {
          Sentry.captureException(e);
        }

        setIntersectionObserverReady(true);
      }
    };

    loadIntersectionObserverPolyfill();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return intersectionObserverReady;
};
