import { Locale } from "/types/locale";

import { productionLocales } from "./locales";

const translatedLanguageName = {
  "en-US": "English",
  "pt-BR": "Português",
  "es-ES": "Español",
  "ca-ES": "Català",
  "de-DE": "Deutsch",
  "it-IT": "Italiano",
  "fr-FR": "Français",
  "sv-SE": "Svenska",
  "id-ID": "Bahasa Indonesia",
  "pl-PL": "Polski",
  "ja-JP": "日本語",
  "zh-TW": "繁體中文",
  "zh-CN": "简体中文",
  "ko-KR": "한국어",
  "th-TH": "ภาษาไทย",
  "nl-NL": "Nederlands",
  "hu-HU": "Magyar",
  "vi-VN": "Tiếng Việt",
  "cs-CZ": "Čeština",
  "da-DK": "Dansk",
  "fi-FI": "Suomi",
  "uk-UA": "Українська",
  "el-GR": "Ελληνικά",
  "ro-RO": "Română",
  "nb-NO": "Norsk",
  "sk-SK": "Slovenčina",
  "tr-TR": "Türkçe",
  "ru-RU": "Русский",
};

if (process.env.NODE_ENV !== "production") {
  productionLocales.forEach((locale) => {
    if (!translatedLanguageName[locale as Locale]) {
      throw new Error(`(${locale}) is missing a translated language name. Please add it to 'translatedLanguageName'`);
    }
  });
}

export default translatedLanguageName;
