import { useEffect, useRef, useState } from "react";

import { useIntersectionObserverReady } from "/shared/hooks/dom/useIntersectionObserverReady";

export default function useOverflowScrollElements(shouldObserve: boolean, assumeContentWillOverflow = false) {
  const scrollRef = useRef<HTMLDivElement>(null);
  const leftRef = useRef<HTMLDivElement>(null);
  const rightRef = useRef<HTMLDivElement>(null);
  const [overflowElementsToShow, setOverflowElementsToShow] = useState<{
    left: boolean;
    right: boolean;
  }>({
    left: false,
    right: assumeContentWillOverflow,
  });

  const intersectionObserverReady = useIntersectionObserverReady();

  useEffect(() => {
    if (!shouldObserve || !intersectionObserverReady) {
      return () => {};
    }

    const scrollElement = scrollRef.current;
    const leftElement = leftRef.current;
    const rightElement = rightRef.current;

    if (!scrollElement) {
      return () => {};
    }

    if (!leftElement) {
      return () => {};
    }

    if (!rightElement) {
      return () => {};
    }

    const observers = [leftElement, rightElement].map((element) => {
      const isLeft = element === leftElement;
      const observer = new IntersectionObserver(
        ([entry]) => {
          setOverflowElementsToShow((prevState) => {
            if (isLeft) {
              return {
                ...prevState,
                left: !entry.isIntersecting,
              };
            }

            return {
              ...prevState,
              right: !entry.isIntersecting,
            };
          });
        },
        { root: scrollElement },
      );

      observer.observe(element);

      return () => {
        observer.unobserve(element);
      };
    });

    return () => {
      observers.map((fn) => fn());
    };
  }, [intersectionObserverReady, shouldObserve]);

  return {
    overflowElementsToShow,
    ref: scrollRef,
    leftRef,
    rightRef,
  };
}
