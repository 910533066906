import { useMemo } from "react";

import { Spaces } from "/types/spaces";

import { SpacingProps } from "../Spacing/spacing";

interface Props {
  index: number;
  childrenCount: number;
  spaceBetween: Spaces;
  addBottomMargin: boolean;
  children: (props: { spacingProps: SpacingProps; lastChild: boolean }) => JSX.Element;
}

function useSpacingProps({ index, childrenCount, spaceBetween, addBottomMargin }: Props) {
  const lastChild = index >= childrenCount - 1;
  const spacingProps: SpacingProps = useMemo(() => {
    const spacing: SpacingProps = {};

    if (!lastChild) {
      spacing.marginRight = spaceBetween;
    }

    if (addBottomMargin) {
      spacing.marginBottom = spaceBetween;
    }

    return spacing;
  }, [addBottomMargin, lastChild, spaceBetween]);

  return {
    lastChild,
    spacingProps,
  };
}

export default function ChildComponentWithSpacingProps(props: Props) {
  const { spacingProps, lastChild } = useSpacingProps(props);

  return props.children({ spacingProps, lastChild });
}
