import { availableLocales } from "./locales";

function getAllDuplicateLanguages() {
  const dupes = new Set<string>();
  const track: { [lang: string]: boolean } = {};

  availableLocales.forEach((locale) => {
    const [language] = locale.split("-");

    if (track[language]) {
      dupes.add(language);
    } else {
      track[language] = true;
    }
  });

  return dupes;
}

const duplicateLanguages = getAllDuplicateLanguages();

/**
 * Return language only when there is <= 1 regions, otherwise return full locale including the region
 */
export default function getLangFromLocale(locale: string) {
  const [language] = locale.split("-");

  // keep region because we have more than one region available
  if (duplicateLanguages.has(language)) {
    return locale;
  }

  return language;
}

export function getAllAvailableLangs() {
  return new Set(availableLocales.map(getLangFromLocale));
}
